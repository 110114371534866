import { useEffect, useState } from "react";

import {
  CUSTOM_FIELD_TYPE,
  IActivity as IBEActivity,
  IActivityReport as IBEActivityReport,
  IItemSafe as IBEItem,
  IList as IBEList,
  IMatrix,
  IMetaExpressionOptions,
  IMobileUser,
  mxolParams,
  scolParams,
} from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { getSignedInUser } from "containers/authentication/redux/selector";
import { getCustomerById } from "containers/calendar/utils/getCustomerNameById";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allListsSelector } from "containers/lists/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { allActivitiesSelector } from "containers/workflows/redux/selectors";
import prepareReportStateForBackend from "containers/workflows/subcategories/activities/utils/prepareReportStateForBackend";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
} from "model/entities/Workflow";

interface IUseMetaExpressionOptions {
  viewMode: TViewMode;
  report: IActivityReportInTable;
  activity: IActivity;
  workflow?: IWorkflow;
}

const useMetaExpressionOptions = ({
  viewMode,
  report,
  activity,
  workflow,
}: IUseMetaExpressionOptions) => {
  const [metaExpressionOptions, setMetaExpressionOptions] = useState({});
  const activities = useSelector(allActivitiesSelector);
  const lists = useSelector(allListsSelector);
  const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);
  const selectedClient = useSelector(getSelectedClient);

  const linkedMobileUser: IMobileUser | undefined = mobileUsers.find(
    (mu) => mu.email && mu.email === signedInUser.id
  ) as IMobileUser | undefined;
  useEffect(() => {
    const scolParamsArray: scolParams[] = [];
    const mxolParamsArray: mxolParams[] = [];

    _.forEach(activity.questionnaire?.questions, (question) => {
      const answer = report[question.tag];
      const listId = question?.list_id;
      const list = listId ? _.find(lists, { id: listId }) : undefined;

      if (question.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
        scolParamsArray.push({
          scolItem: answer?.value ?? answer,
          scolList: list as IBEList,
        });
      }
      if (
        question.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST &&
        question.matrix
      ) {
        mxolParamsArray.push({
          matrix: question.matrix as IMatrix,
          mxolItems: list?.items as unknown as IBEItem[],
          tag: question.tag,
          scolParams: undefined, // Placeholder for future implementation
        });
      }
    });

    const activityReport: IBEActivityReport = prepareReportStateForBackend(
      report,
      activity
    ) as IBEActivityReport;
    const customer = activityReport
      ? getCustomerById(lists, activityReport._customer_id)
      : undefined;
    const customer_list = lists.find((list) => list.id === "customer");
    const newMetaExpressionOptions: IMetaExpressionOptions = {
      activity_report: activityReport,
      activity: activity as IBEActivity,
      activities: activities as IBEActivity[],
      workflow,
      mxolParams: mxolParamsArray,
      scolParams: scolParamsArray,
      user: linkedMobileUser,
      client: selectedClient,
      ...(customer
        ? {
            item: customer as any as IBEItem,
            list: customer_list as IBEList,
          }
        : {}),
    };

    setMetaExpressionOptions(newMetaExpressionOptions);
    return () => {};
  }, [
    viewMode,
    report,
    activity,
    lists,
    activities,
    workflow,
    linkedMobileUser,
    selectedClient,
  ]);

  return { metaExpressionOptions };
};

export default useMetaExpressionOptions;
