import { useEffect, useState } from "react";

import { Box, Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ACTION_CODE, isOptimetriksRole } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import { White, YellowSecondary } from "assets/colors";
import CustomDialogWarning from "components/Dialog/CustomDialogWarning";
import InputText from "components/Input/InputText/InputText";
import { getSignedInUser } from "containers/authentication/redux/selector";
import {
  createApiKeyAction,
  getApiKeyAction,
  getDBAccessAction,
  updateApiKeyAction,
} from "containers/clients/redux/actions";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allWebUsersComposedSelector } from "containers/users/redux/selectors/webUsersSelector";
import useActions from "hooks/useActions";
import useTranslations from "hooks/useTranslations";
import { TViewMode } from "model/application/modal/CreateEditModal";

export interface IDeveloperTabProps {
  id: string;
  dbname: string;
  viewMode: TViewMode;
}

const DeveloperTab: React.FunctionComponent<IDeveloperTabProps> = ({
  id,
  dbname,
  viewMode,
}) => {
  const lang = useTranslations();
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const [loading, setloading] = useState(true);
  const [dbCredential, setDBCredential] = useState({
    id: id,
    username: "",
    password: "",
  });
  const [apiKey, setApiKey] = useState("");
  const [openApiWarningDialog, setOpenApiWarningDialog] = useState(false);

  const getDBAccess = useActions(getDBAccessAction);
  const getApiKey = useActions(getApiKeyAction);
  const updateApiKey = useActions(updateApiKeyAction);
  const createApiKey = useActions(createApiKeyAction);
  const webUsers = useSelector(allWebUsersComposedSelector);
  const selectedClient = useSelector(getSelectedClient);
  const userLogged = useSelector(getSignedInUser);
  const hasAccessToApiKeys = () => {
    const webUser = webUsers.find((wu: any) => wu.email === userLogged.id);
    const profile =
      webUser &&
      _.find(
        selectedClient.access_right_profiles ?? [],
        (pr) => pr.name === webUser.role
      );
    return (
      profile?.actions[ACTION_CODE.EDIT_WORKFLOW] ||
      isOptimetriksRole(selectedClient.profile)
    );
  };

  useEffect(() => {
    (async () => {
      setloading(true);

      const dbAccessClient = await getDBAccess(id);
      setDBCredential({
        id: id,
        username: dbAccessClient.username,
        password: dbAccessClient.password,
      });

      const data = await getApiKey();
      setApiKey(data.apiKey);

      setloading(false);
    })();
  }, [id, getDBAccess, getApiKey]);

  const refreshApiKey = async () => {
    if (!apiKey || apiKey == "") {
      const res = await createApiKey();
      setApiKey(res.apiKey);
    } else {
      const res = await updateApiKey();
      setApiKey(res.apiKey);
    }
    setOpenApiWarningDialog(false);
  };

  return (
    <>
      {!loading ? (
        <Box>
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.id}
            name="id"
            key={`id${id}`}
            lang={label.inputDeveloperId}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
          {id ? (
            <InputText
              disabled
              defaultValue={dbname}
              name="dbname"
              key={`${dbname}`}
              lang={label.inputDBName}
              onChange={() => {}}
              viewMode={"VIEW"}
              replaceSpaces
              lowercase
              debounceDuration={400}
            />
          ) : null}
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.username}
            name="username"
            key={`username${dbCredential.username}`}
            lang={label.inputDeveloperUsername}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
          <InputText
            viewMode={viewMode}
            disabled
            defaultValue={dbCredential.password}
            name="password"
            lang={label.inputDeveloperPassword}
            key={`password${dbCredential.password}`}
            onChange={() => {}}
            replaceSpaces
            lowercase
          />
          {hasAccessToApiKeys() ? (
            <Box>
              <InputText
                viewMode={viewMode}
                disabled
                defaultValue={apiKey}
                name="apikey"
                lang={label.inputDeveloperApiKey}
                key={`apikey${apiKey}`}
                onChange={() => {}}
                replaceSpaces
              />
              <Button
                style={{ background: YellowSecondary, color: White }}
                onClick={() => setOpenApiWarningDialog(true)}
                disabled={!hasAccessToApiKeys()}
              >
                {label.inputDeveloperApiKey.selectModal.description}
              </Button>
              <CustomDialogWarning
                isOpen={openApiWarningDialog}
                onClose={() => setOpenApiWarningDialog(false)}
                onConfirmAction={() => refreshApiKey()}
                lang={{
                  title: label.inputDeveloperApiKey.warningMessage.title,
                  description:
                    label.inputDeveloperApiKey.warningMessage.description,
                }}
                rootLang={lang}
              />
            </Box>
          ) : null}
        </Box>
      ) : (
        <Skeleton animation="wave" style={{ height: "30px" }} />
      )}
    </>
  );
};

export default DeveloperTab;
