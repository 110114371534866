import React from "react";

import { Grid } from "@material-ui/core";

import * as colors from "assets/colors";
import { IKPI } from "model/entities/Dashboard";

import TimeoutChart from "../Chart/TimeoutChart";
import CustomGauge from "./CustomGauge";

interface IGaugeContainerProps {
  index: number;
  gauge: IKPI;
  downloadDashboard?: () => any;
}

export const GaugeContainer: React.FunctionComponent<IGaugeContainerProps> = ({
  index,
  gauge,
  downloadDashboard,
}) => {
  const getColor = (index: number) => {
    let color;
    switch (index % 4) {
      case 0: {
        color = colors.teal;
        break;
      }
      case 1: {
        color = colors.blue;
        break;
      }
      case 2: {
        color = colors.lightblue;
        break;
      }
      case 3: {
        color = colors.lightpurple;
        break;
      }
      default: {
        color = colors.darkgray;
      }
    }
    return color;
  };
  const color = getColor(index);
  return (
    <Grid
      item
      lg={3}
      md={6}
      sm={6}
      xs={12}
      key={gauge.tag}
      style={{
        minWidth: "300px",
      }}
    >
      {gauge.data &&
      gauge.data.error &&
      gauge.data.error === "timeout" &&
      downloadDashboard ? (
        <TimeoutChart
          chartType={gauge.type}
          downloadDashboard={downloadDashboard}
        />
      ) : (
        <CustomGauge gauge={gauge} color={color}></CustomGauge>
      )}
    </Grid>
  );
};

export default GaugeContainer;
