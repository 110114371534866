import { useEffect, useState } from "react";

import { Box } from "@material-ui/core";
import { IMetaExpressionOptions } from "fieldpro-tools";
import _ from "lodash";
import { useSelector } from "react-redux";

import InputBaseLayout from "components/Input/InputBaseLayout";
import InputBoolean from "components/Input/InputBoolean";
import InputDate from "components/Input/InputDate";
import InputFile from "components/Input/InputFile/InputFile";
import InputGPS from "components/Input/InputGPS";
import InputImage from "components/Input/InputImage";
import InputMatrix from "components/Input/InputMatrix";
import { getItemCategory } from "components/Input/InputMatrix/utils/getItemCategory";
import { getListCategories } from "components/Input/InputMatrix/utils/getMatrixCategories";
import {
  getCategoryTagKey,
  getCustomFieldColumns,
  getMatrixFieldsSchema,
  getNameKey,
  getTagKey,
  isQuestion,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputMultipleText from "components/Input/InputMultipleText";
import InputRating from "components/Input/InputRating";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import InputTime from "components/Input/InputTime";
import { getExtensionFromFileName } from "components/Input/utils";
import MatrixViewer from "components/Matrix/MatrixViewer";
import { TABLE_COLUMN_TYPE, TRowType } from "components/Table/model";
import InputPlaceholder from "components/Typography/InputPlaceholder";
import { getInputTextTypeFromAttributeType } from "containers/lists/subcategories/items/utils/getInputTextTypeFromAttributeType";
import {
  allMetaExpressionsSolvedSelector,
  getIsComputingMetaExpression,
} from "containers/workflows/redux/meta_expressions/selectors";
import { getOptionsFromList } from "containers/workflows/subcategories/activities/utils";
import { replaceMetaExpressionInString } from "containers/workflows/subcategories/activities/utils/replaceMetaExpressionInString";
import { IFormState } from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TInputAttributeLang } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IList, IListSchema } from "model/entities/List";
import { CUSTOM_FIELD_TYPE, IQuestion } from "model/entities/Workflow";
import { getFileName } from "utils/gcs";
import { sleep } from "utils/sleep";
import { stringToBoolean } from "utils/utils";

import InputCompute from "../InputCompute";
import { IInputImageProps } from "../InputImage/InputImage";
import InputLabel from "../InputLabel";
import InputMultipleSelectOnList from "../InputMultipleSelectOnList";
import InputPhone from "../InputPhone/InputPhone";

interface IInputCustomFieldProps {
  viewMode: TViewMode;
  formState: IFormState<any>;
  customField: (IQuestion | IListSchema) & {
    [key: string]: any;
  };
  answer: any;
  list?: IList; // For "on list" fields only (MCOL, SCOL, MXOL)
  metaExpressionOptions?: IMetaExpressionOptions;
  onUpdateGeneralState?: (attrChanged: string, attrValue: any) => void;
  debounceDuration?: number;
  disabled?: boolean;
  // Extra props, specific to one (or more, but not all) Input types
  extraProps?: {
    [CUSTOM_FIELD_TYPE.PICTURE]: Partial<IInputImageProps>; // TODO: type based on inputType
  };
}

/**
 * will receive an activity report's questions and answers and generate the corresponding components to display them
 * @param question questions from the custom activity
 * @param answer  answers to the questions of the activity
 * @lists optional - a list of items used in multiple choice fields
 * @returns input component based on the question type
 */
const InputCustomField = ({
  customField,
  answer,
  viewMode,
  formState,
  metaExpressionOptions = {},
  onUpdateGeneralState,
  list,
  debounceDuration,
  disabled,
  extraProps,
}: IInputCustomFieldProps) => {
  const solvedMetaExpressions = useSelector(allMetaExpressionsSolvedSelector);
  const isComputingMetaExpressions = useSelector(getIsComputingMetaExpression);

  const lang = useTranslations();
  const reportLang = lang.containers.workflows.subCategories.activityReports;

  const [showErrors, setShowErrors] = useState(false);

  const onChange = async (attrValue: any, attrName: string) => {
    formState?.handleInputChange(attrValue, attrName);
    // avoid glitch where the error appears for a split second when filling mandatory field
    await sleep(300);
    setShowErrors(true);
  };

  function getError(name: string) {
    return formState?.errors?.[name];
  }

  const isCustomFieldQuestion = isQuestion(customField);
  const tagKey = getTagKey(customField);
  const nameKey = getNameKey(customField);

  const keepViewMode =
    isCustomFieldQuestion &&
    !customField.deprecated &&
    (customField.type === CUSTOM_FIELD_TYPE.BARCODE_SCANNER ||
      (customField as IQuestion).query_template);

  const defaultLabel = customField[nameKey];
  const defaultTooltip = isCustomFieldQuestion
    ? (customField as IQuestion)?.description
    : "";

  const defaultProps = {
    name: customField[tagKey],
    viewMode: keepViewMode ? "VIEW" : viewMode,
    viewStacked: true,
    // defaultValue: answer,
    label: defaultLabel,
    title: defaultLabel,
    lang: {
      title: defaultLabel,
      tooltip: defaultTooltip,
    },
    highlightContent: viewMode === "VIEW",
    required: customField.required,
    disabled,
    ...(showErrors && { error: getError(customField[tagKey]) }),
  };

  // TODO: we should rename this "onBlur" or "onChange",
  // and always call it during the same type of event
  // In the case of onChange, we should add debounceDuration to text fields to make them more performant
  // Other solution: detect change directly at the form state level,
  // and call "ME update" function on all attributes that have changed value.
  // This could be more performant if useEffect is called after a batch of updates are made

  const onUpdate = (key: string, value: any) => {
    if (!onUpdateGeneralState) {
      return;
    }

    onUpdateGeneralState(key, value);
  };

  const CLEAR_OPTION = {
    key: "__CLEAR",
    label: lang.components.inputSelect.clear,
  };

  useEffect(() => {
    if (
      customField.type == CUSTOM_FIELD_TYPE.PLAIN_TEXT &&
      (viewMode == "CREATE" || // should not resolve this in VIEW or EDIT mode
        (viewMode == "VIEW" && (!answer || answer.match(/\$([^}]*)/g))) || // for backward compatibility where there is no value saved for display text
        (viewMode == "EDIT" && (!answer || answer.match(/\$([^}]*)/g)))) &&
      isComputingMetaExpressions != true
    ) {
      // const label = resolveMetaExpressionInString(
      //   defaultProps.label,
      //   metaExpressionOptions ?? {},
      //   ""
      // );
      const label = replaceMetaExpressionInString({
        // Need to use backend here to resolve "customer"
        resolvedMetaExpressions: solvedMetaExpressions,
        text: defaultProps.label,
        isComputingMetaExpressions,
      });

      if (answer !== label) {
        formState?.handleInputChange(label, customField[tagKey]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solvedMetaExpressions, isComputingMetaExpressions]);

  switch (customField.type) {
    case CUSTOM_FIELD_TYPE.PLAIN_TEXT:
      return (
        <InputLabel
          title={answer}
          tooltip={defaultTooltip}
          required={defaultProps.required}
          viewMode={"CREATE"} // to hide the ":" in the label
        />
      );
    case CUSTOM_FIELD_TYPE.COMPUTE: {
      if (viewMode === "VIEW") {
        // Same as normal text
        return (
          <InputText
            key={customField[tagKey]}
            {...defaultProps}
            defaultValue={answer}
            required={customField.required}
            label={customField[nameKey]}
            type={getInputType(customField.type)}
            onChange={() => {}}
          />
        );
      }
      return (
        <InputCompute
          key={customField[tagKey]}
          {...defaultProps}
          operations={(customField as IQuestion)?.operations}
          metaExpressionOptions={metaExpressionOptions}
          onResultChange={(result) => {
            onChange(result, defaultProps.name);
            onUpdate(customField[tagKey], result);
          }}
          // onBlur={() => onUpdate(customField[tagKey], answerResolved)}
          debounceDuration={debounceDuration}
        />
      );
    }
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.BARCODE_SCANNER:
      return (
        <InputText
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={answer}
          type={getInputType(customField.type)}
          onChange={onChange}
          onBlur={() => onUpdate(customField[tagKey], answer)}
          debounceDuration={debounceDuration}
        />
      );
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      return (
        <InputPhone
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={answer}
          label={customField[nameKey]}
          onChange={onChange}
          onBlur={() => onUpdate(customField[tagKey], answer)}
        />
      );
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_TEXT: {
      if (viewMode === "EDIT") {
        return <NotEditableYet label={customField[nameKey]} />;
      }

      const inputTextType = getInputTextTypeFromAttributeType(customField.type);
      if (
        inputTextType === INPUT_TEXT_TYPE.INTEGER ||
        inputTextType === INPUT_TEXT_TYPE.DECIMAL ||
        inputTextType === INPUT_TEXT_TYPE.TEXT
      ) {
        return (
          <InputMultipleText
            key={customField[tagKey]}
            defaultValue={answer || []}
            required={customField.required}
            label={customField[nameKey]}
            type={inputTextType}
            onChange={onChange}
            onBlurCapture={() => onUpdate(customField[tagKey], answer || [])}
            viewMode={defaultProps.viewMode}
            name={customField[tagKey]}
            viewStacked
          />
        );
      } else {
        return <div />;
      }
    }

    case CUSTOM_FIELD_TYPE.DATE_PICKER: {
      return (
        <InputDate
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={answer}
          onChange={(v: any, n: string) => {
            onChange(v, n);
            onUpdate(n, v);
          }}
        />
      );
    }
    case CUSTOM_FIELD_TYPE.TIME_PICKER: {
      return (
        <InputTime
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={answer}
          onChange={(v: any, n: string) => {
            onChange(v, n);
            onUpdate(n, v);
          }}
          viewInLocalTimeZone
        />
      );
    }
    case CUSTOM_FIELD_TYPE.BOOLEAN:
      return (
        <InputBoolean
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={stringToBoolean(answer)}
          onChange={(v: any, n: string) => {
            onChange(v, n);
            onUpdate(n, v);
          }}
        />
      );
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
      const defaultValue: IOption[] = _.compact(answer);

      // CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE
      let options = _.compact(customField.options);

      if (customField.type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST) {
        // We add the defaultValue options to make sure they are displayed in VIEW mode
        // TODO: all items are not loaded unfortunately...
        options = _.concat(
          getOptionsFromList(list),
          defaultValue.filter((v) => v.key !== CLEAR_OPTION.key)
        );
        options = _.uniqBy(options, "key");
      }
      options = options.concat([CLEAR_OPTION]);

      const Component =
        customField.type === CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST &&
        list?.id
          ? InputMultipleSelectOnList
          : InputMultipleSelect;

      return (
        <Component
          key={customField[tagKey]}
          {...defaultProps}
          multipleSelection
          defaultValue={defaultValue}
          options={options}
          onChange={(v: any, n: string) => {
            onChange(v, n);
            onUpdate(n, v);
          }}
          langlabel={{
            title: customField[nameKey],
            tooltip: isCustomFieldQuestion
              ? (customField as IQuestion)?.description || ""
              : "",
          }}
          listId={_.get(list, "id", "")}
          lang={lang}
        />
      );
    }
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST: {
      let options =
        customField.type == CUSTOM_FIELD_TYPE.SINGLE_CHOICE
          ? _.compact(customField.options)
          : getOptionsFromList(list);
      const defaultValue = answer?.key
        ? _.compact([answer])
        : _.compact([options.find((o) => o.key == answer)]); // take care of default value, which is a string

      if (customField.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST) {
        // We add the defaultValue options to make sure they are displayed in VIEW mode
        // TODO: all items are not loaded unfortunately...
        options = _.concat(
          options,
          defaultValue.filter((v) => v.key !== CLEAR_OPTION.key)
        );
        options = _.uniqBy(options, "key");
      }
      options = options.concat([CLEAR_OPTION]);

      const Component =
        customField.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST && list?.id
          ? InputMultipleSelectOnList
          : InputMultipleSelect;
      return (
        <Component
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={defaultValue}
          langlabel={{
            title: customField[nameKey],
            tooltip: isCustomFieldQuestion
              ? (customField as IQuestion)?.description || ""
              : "",
          }}
          multipleSelection={false}
          options={options}
          // CAREFUL: answer needs to be wrapped/unwrapped
          onChange={(value, name) => {
            onChange(value?.[0], name);
            onUpdate(name, value?.[0]);
          }}
          lang={lang}
          listId={_.get(list, "id", "")}
        />
      );
    }
    case CUSTOM_FIELD_TYPE.PICTURE:
    case CUSTOM_FIELD_TYPE.SIGNATURE:
      return (
        <InputImage
          key={customField[tagKey]}
          {...defaultProps}
          lang={{ title: defaultProps.title } as TInputAttributeLang}
          defaultValue={_.isString(answer) ? answer : answer?.url}
          onChange={function (value, name) {
            formState.handleFileChange(value, name);
            onUpdate(name, value);
          }}
          {...extraProps?.[CUSTOM_FIELD_TYPE.PICTURE]}
        />
      );

    // case CUSTOM_FIELD_TYPE.DATE_PICKER:
    //   return <InputDate {...defaultProps} value={defaultProps.defaultValue} />;
    case CUSTOM_FIELD_TYPE.GPS:
      return (
        <InputGPS
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={answer}
          langlabel={defaultProps.lang}
          lang={lang}
          onChange={(v: any, n: string) => {
            formState.handleGPSChange(v, n);
            onUpdate(n, v);
          }}
        />
      );
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
      if (!list) {
        console.error("Missing list for matrix field", { customField });
        return null;
      }

      if (viewMode === "EDIT" || viewMode === "CREATE") {
        const matrixData = formState.attributes[customField[tagKey]];
        let categories: any = [];
        if (matrixData?.categories) {
          categories = matrixData.categories;
        }

        let rows: TRowType[] = [];
        if (matrixData?.rows) {
          rows = _.map(matrixData.rows, function (row) {
            return {
              ...row,
              _category_id: getItemCategory(
                row._item_id,
                list,
                getCategoryTagKey(customField)
              ),
            };
          });
        }

        const { viewMode: _viewMode, ...rest } = defaultProps;

        categories = getListCategories(list, customField);
        const matrixFields = getMatrixFieldsSchema(customField);

        return (
          <InputMatrix
            key={customField[tagKey]}
            {...rest}
            label={customField[nameKey]}
            required={customField.required}
            categories={getListCategories(list, customField)}
            rows={rows}
            columns={getCustomFieldColumns(matrixFields)}
            list={list}
            customFields={matrixFields}
            customFieldMatrix={customField}
            name={customField[tagKey]}
            onChange={function (newRows, name) {
              const newMatrixData = {
                ...matrixData,
                rows: newRows,
                categories,
              };

              formState.handleInputChange(newMatrixData, name);
              onUpdate(customField[tagKey], newRows);
            }}
          />
        );
      }

      const rows = fixRowCategories(answer?.rows, list, customField);
      const categories = getListCategories(list, customField);

      const ITEM_NAME_COLUMN = {
        label: reportLang.columns.itemName || "",
        type: TABLE_COLUMN_TYPE.TEXT,
        name: "_name",
      };

      const columns = [
        ITEM_NAME_COLUMN,
        ...getCustomFieldColumns(getMatrixFieldsSchema(customField)),
      ];

      return (
        <Box padding="8px 0 24px">
          <MatrixViewer
            {...defaultProps}
            // onChange={onChange} // SOON
            rows={rows}
            columns={columns}
            categories={categories}
            key={customField[tagKey]}
          />
        </Box>
      );

    case CUSTOM_FIELD_TYPE.RATING:
      return (
        <InputRating
          key={customField[tagKey]}
          {...defaultProps}
          required={customField.required}
          onChange={(v: any, n: string) => {
            onChange(v, n);
            onUpdate(n, v);
          }}
          value={Number(answer)}
          max={10}
          step={1}
        />
      );
    // case CUSTOM_FIELD_TYPE.TIME_PICKER:
    //   return <InputTime {...defaultProps} />;
    case CUSTOM_FIELD_TYPE.UPLOAD_FILE:
      const _filename = getFileName(answer?.url);
      return (
        <InputFile
          key={customField[tagKey]}
          {...defaultProps}
          defaultValue={{
            ...answer,
            filename: _filename,
            ...(_filename && {
              type: getExtensionFromFileName(_filename),
            }),
          }}
          onChange={(v: any, n: string) => {
            formState.handleFileChange(v, n);
            onUpdate(n, v);
          }}
        />
      );
    default:
      return <div></div>;
  }
};

const NotEditableYet = (props: { label: string }) => {
  return (
    <InputBaseLayout viewMode="CREATE" label={props.label}>
      <InputPlaceholder placeholder="Not editable yet" />
    </InputBaseLayout>
  );
};

/**
 * will get a custom field type and return its corresponding input_text_type if available
 * @param type a custom field type object
 * @returns a input text type equivalent
 */
export const getInputType = (type: CUSTOM_FIELD_TYPE): INPUT_TEXT_TYPE => {
  switch (type) {
    case CUSTOM_FIELD_TYPE.DECIMAL:
      return INPUT_TEXT_TYPE.DECIMAL;
    case CUSTOM_FIELD_TYPE.TEXT:
      return INPUT_TEXT_TYPE.TEXT;
    case CUSTOM_FIELD_TYPE.INTEGER:
      return INPUT_TEXT_TYPE.INTEGER;
    case CUSTOM_FIELD_TYPE.COMPUTE:
      return INPUT_TEXT_TYPE.INTEGER;
    default:
      return INPUT_TEXT_TYPE.TEXT;
  }
};

export default InputCustomField;

export function fixRowCategories(
  rows: TRowType[],
  list: IList,
  customField: IQuestion | IListSchema
) {
  return _.map(rows, function (row) {
    return {
      ...row,
      _category_id: getItemCategory(
        row._item_id,
        list,
        getCategoryTagKey(customField)
      ),
    };
  });
}
