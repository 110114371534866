import _ from "lodash";

import {
  buildBooleanFilter,
  buildDateFilter,
  buildHierarchyFilter,
  buildMoreFilter,
  buildMultipleChoiceFilter,
  buildMultipleChoiceOnListFilter,
  buildNumberFilter,
  buildTextFilter,
} from "components/Filter/prepareFiltersUtils";
import FILTER_TYPE, { FILTER_TAG } from "components/Filter/TypeFilter";
import { getLangObject } from "lang/utils";
import { IOption } from "model/application/components";
import { IFilter } from "model/application/Filter";
import TLang from "model/application/Lang";
import { IClient } from "model/entities/Client";
import {
  CUSTOM_FIELD_TYPE,
  IList,
  IListSchema,
  LIST_SCOPE,
  LIST_TYPE,
} from "model/entities/List";
import { ITeam, ITeamSelector } from "model/entities/Team";
import { IMobileUser, IWebUser } from "model/entities/User";

import { IQuery } from "../utils";

export const prepareFilters = (
  list: IList,
  mobileUsers: IMobileUser[],
  webUsers: IWebUser[],
  teams: ITeam[],
  teamsLevels: ITeamSelector[],
  query: IQuery = {},
  client: IClient,
  lang: TLang,
  territoriesSchema?: IListSchema[],
  calledFrom: "list" | "map" = "list"
): IFilter[] => {
  /**
   * return the filters in this order:
   * 1. All the custom attributes
   * 2. All the scope-related filters
   * 3. Other exotic filters (need to have the scope-related filters first)
   * 4. All the meta-attributes
   * 5. The "more" filter
   */

  let filters: IFilter[] = [];

  // 1. All the custom attributes filters
  filters = insertCustomFiltersAndReturnIt(filters, list, query);

  // 2. List type related filters
  if (list.list_type === LIST_TYPE.CUSTOMER) {
    filters.push({
      isSecondaryFilter: true,
      label: lang.containers.lists.subCategories.items.columns.lastVisitDate,
      tag: "_last_visit_date",
      type: FILTER_TYPE.DATE,
      value: undefined,
    });
  }

  // 3. All the scope-related filters
  if (list.scope && list.scope !== LIST_SCOPE.GLOBAL) {
    addScopeFilters({
      filters,
      list,
      client,
      teamsLevels,
      teams,
      mobileUsers,
      params: query,
      lang,
    });
  }

  // 4. Other exotic filters (need to have the scope-related fitlers first)
  filters = insertExoticFiltersAndReturnIt(
    filters,
    calledFrom,
    lang,
    territoriesSchema
  );

  // 5. All the meta-attributes
  filters = insertDefaultMetadataColumnsAndReturnIt(
    filters,
    mobileUsers,
    webUsers,
    lang
  );

  // 6. The "more" filter
  return insertMoreFilterAndReturnIt(list, calledFrom, query, filters);
};

const insertExoticFiltersAndReturnIt = (
  filters: IFilter[],
  calledFrom: "list" | "map",
  lang: TLang,
  territoriesSchema: IListSchema[] | undefined
) => {
  if (calledFrom === "list") {
    if (territoriesSchema) {
      const langKey = lang.containers.lists.subCategories.territories;
      filters.push({
        isSecondaryFilter: false,
        label: langKey.columns.id,
        tag: "territory_id", // targetting the "_id" property of the territory list
        type: FILTER_TYPE.TEXT,
        value: undefined,
      });

      filters.push({
        isSecondaryFilter: false,
        label: langKey.columns.name,
        tag: "territory_name", // targetting the "_name" property of the territory list
        type: FILTER_TYPE.TEXT,
        value: undefined,
      });

      if (territoriesSchema) {
        _.map(territoriesSchema, (ts) => {
          const filter = convertAttributeToFilter(ts);
          if (!filter || filter.type === FILTER_TYPE.TEXT) {
            return;
          }
          filter.tag = `territory_${filter.tag}`;
          filter.label = `${filter.label}`;
          filters.push(filter);
        });
      }
    }

    filters.push({
      isSecondaryFilter: false,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .businessId,
      singleSelection: false,
      tag: "_displayed_business_id",
      type: FILTER_TYPE.TEXT,
      value: undefined,
    });
  } else {
    // called from map
  }

  const ownersFilter = filters.find((f) => f.tag === FILTER_TAG.OWNERS);
  if (ownersFilter) {
    filters.push({
      ...ownersFilter,
      tag: "territory__owners", // targetting the "_owners" property of the territory list
      label:
        lang.containers.lists.subCategories.items.createEditModal
          .inputTerritoriesOwner.options.territoryOwner,
    });
  }
  return filters;
};

export const insertCustomFiltersAndReturnIt = (
  filters: IFilter[],
  list: IList,
  query: IQuery
) => {
  const isPrimaryFilter = (tag: string) => {
    return (
      _.includes(list?.important, tag) ||
      _.includes(query[FILTER_TAG.MORE_FILTER], tag) ||
      isDefaultListAttribute({
        listType: list.list_type,
        tag,
      })
    );
  };

  list.schema.forEach((att) => {
    const filter = convertAttributeToFilter(att);
    if (!filter) {
      return;
    }
    // we display the filters related to the attributes if they are important or if they are selected in the more filter
    const displayFilter = isPrimaryFilter(att.column_tag);
    if (!displayFilter) {
      filter.isSecondaryFilter = true;
    }
    filters.push(filter);
  });
  return filters;
};

export const insertDefaultMetadataColumnsAndReturnIt = (
  filters: IFilter[],
  mobileUsers: IMobileUser[],
  webUsers: IWebUser[],
  lang: TLang
) => {
  // in that order: _id, _displayed_name, _archived, _created_at, _updated_at, _created_by, _updated_by, _created_source, _updated_source
  filters.push({
    isSecondaryFilter: false,
    label: lang.containers.lists.subCategories.items.columns.itemId,
    singleSelection: false,
    tag: "_id",
    type: FILTER_TYPE.TEXT,
    value: undefined,
  });

  // add display_name filter
  filters.push({
    isSecondaryFilter: false,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.itemName,
    singleSelection: false,
    tag: "_displayed_name",
    type: FILTER_TYPE.TEXT,
    value: undefined,
  });

  // add _archived filter ==> to manage _active filter
  filters.push({
    isSecondaryFilter: true,
    label: lang.genericTerms.archived,
    tag: "_archived",
    type: FILTER_TYPE.BOOLEAN,
    singleSelection: false,
    options: [
      {
        key: "true",
        label: "TRUE",
        value: true,
      },
      {
        key: "false",
        label: "FALSE",
        value: false,
      },
    ],
    value: [],
  });

  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.createdAt,
    tag: "_created_at",
    type: FILTER_TYPE.DATE,
    value: undefined,
  });
  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.updatedAt,
    tag: "_updated_at",
    type: FILTER_TYPE.DATE,
    value: undefined,
  });

  const userOptions = [
    ...mobileUsers.map((mu) => ({
      key: mu.id,
      label: `${mu.first_name} ${mu.last_name} (${mu.phone})`,
    })),
    ...webUsers.map((wu) => ({
      key: wu.id,
      label: `${wu.first_name} ${wu.last_name} (${wu.email})`,
    })),
  ];

  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.createdBy,
    tag: "_created_by",
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    options: userOptions,
    value: [],
  });

  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns.updatedBy,
    tag: "_updated_by",
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    options: userOptions,
    value: [],
  });
  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns
        .createSource,
    tag: "_created_source",
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    options: [
      {
        key: "WEB",
        label: "WEB",
      },
      {
        key: "MOBILE",
        label: "MOBILE",
      },
    ],
    value: [],
  });
  filters.push({
    isSecondaryFilter: true,
    label:
      lang.containers.workflows.subCategories.activityReports.columns
        .updateSource,
    tag: "_updated_source",
    type: FILTER_TYPE.MULTIPLE_CHOICE,
    options: [
      {
        key: "WEB",
        label: "WEB",
      },
      {
        key: "MOBILE",
        label: "MOBILE",
      },
    ],
    value: [],
  });
  return filters;
};

export const insertMoreFilterAndReturnIt = (
  list: IList,
  calledFrom: "list" | "map",
  query: IQuery,
  filters: IFilter[]
): IFilter[] => {
  const options = list.schema
    .filter((att) => {
      if (
        isDefaultListAttribute({
          listType: list.list_type,
          tag: att.column_tag,
        })
      ) {
        return false;
      }
      if (
        (!list.important || !list.important.includes(att.column_tag)) &&
        [
          CUSTOM_FIELD_TYPE.BOOLEAN,
          CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE,
          CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
          CUSTOM_FIELD_TYPE.DATE_PICKER,
          ...(calledFrom === "list"
            ? [
                CUSTOM_FIELD_TYPE.DECIMAL,
                CUSTOM_FIELD_TYPE.INTEGER,
                CUSTOM_FIELD_TYPE.TEXT,
                CUSTOM_FIELD_TYPE.PHONE_NUMBER,
              ]
            : []),
        ].includes(att.type)
      ) {
        return true;
      }
      return false;
    })
    .map((att) => ({
      key: att.column_tag,
      label: att.column_name,
    }));

  filters.push(
    buildMoreFilter(
      _.uniqBy(
        [
          ...filters.map((filter) => ({
            key: filter.tag,
            label: filter.label,
          })),
          ...options,
        ],
        "key"
      )
    )
  );

  return filters.map((f) => {
    if (query[f.tag]) {
      f.value = query[f.tag];
    }
    if (f.tag === FILTER_TAG.MORE_FILTER) {
      f.options = _.filter(f.options, (option) => !isDisplayed(option.key, f));
    }
    return f;
  });
};

export const isDisplayed = (filterTag: string, moreFilter?: IFilter) => {
  if (!moreFilter) {
    return true;
  }
  if (filterTag === FILTER_TAG.MORE_FILTER) {
    return false;
  }
  return _.includes(moreFilter?.value, filterTag);
};

const buildTeamsFilter = (
  teams: ITeamSelector[],
  hierarchy: any[]
): IFilter => {
  const lang = getLangObject();
  const teamsOptions = teams.map((t) => ({
    label: t.name,
    key: t.id,
  }));
  const teamFilter = buildMultipleChoiceFilter(
    teamsOptions,
    lang.components.filters.teams,
    FILTER_TAG.TEAMS
  );
  // add the hierarchy informations
  if (hierarchy) {
    teamFilter.hierarchy = hierarchy;
  }
  return teamFilter;
};

export const convertAttributeToFilter = (
  attribute: IListSchema
): IFilter | undefined => {
  let res;
  switch (attribute.type) {
    case CUSTOM_FIELD_TYPE.DATE_PICKER: {
      res = buildDateFilter(attribute.column_name, attribute.column_tag);
      break;
    }
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.INTEGER: {
      res = buildNumberFilter(attribute.column_name, attribute.column_tag);
      break;
    }
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
    case CUSTOM_FIELD_TYPE.TEXT: {
      res = buildTextFilter(attribute.column_name, attribute.column_tag);
      break;
    }
    case CUSTOM_FIELD_TYPE.BOOLEAN: {
      res = buildBooleanFilter(attribute.column_name, attribute.column_tag);
      break;
    }
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE: {
      if (!attribute.options)
        throw new Error(
          "Multiple choice Attribute must have options in the schema."
        );
      res = buildMultipleChoiceFilter(
        attribute.options || [],
        attribute.column_name,
        attribute.column_tag
      );
      break;
    }
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE: {
      if (!attribute.options)
        throw new Error(
          "Single choice Attribute must have options in the schema."
        );
      // NOTE: this is intentional, the attribute is "single choice" but the filter on this attribute can have multiple choices.
      res = buildMultipleChoiceFilter(
        [{ key: "_", label: "NOTHING" }].concat(attribute.options || []),
        attribute.column_name,
        attribute.column_tag
      );
      break;
    }
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST:
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
      const { column_name, column_tag, list_id } = attribute;
      if (list_id) {
        res = buildMultipleChoiceOnListFilter({
          label: column_name,
          listId: list_id,
          options: [],
          tag: column_tag,
          value: [],
        });
      }
      break;
  }
  return res;
};

interface IAddScopeFiltersArgs {
  filters: IFilter[];
  list: IList;
  client: IClient;
  teams: ITeam[];
  teamsLevels: ITeamSelector[];
  mobileUsers: IMobileUser[];
  params: IQuery;
  lang: TLang;
}

const addScopeFilters = ({
  filters,
  list,
  client,
  teamsLevels,
  teams,
  mobileUsers,
  params,
  lang,
}: IAddScopeFiltersArgs) => {
  const activeTeams = teamsLevels.filter(
    (t) => !t.hasOwnProperty("active") || t.active
  );
  filters.push(buildOwnerFilter(list.scope, mobileUsers, teams, lang));
  if (
    [LIST_SCOPE.MOBILE_USER, LIST_SCOPE.SINGLE_MOBILE_USER].includes(list.scope)
  ) {
    filters.push(buildTeamsFilter(activeTeams, client.hierarchy_dependencies));
  }
  // if there is a hierarchy, add the hierarchy filter
  if (
    client.meta_hierarchy_dependencies &&
    Object.keys(client.meta_hierarchy_dependencies).find(
      (lev) => client.meta_hierarchy_dependencies[lev].level_type_number >= 0
    )
  ) {
    filters.push(
      buildHierarchyFilter(
        client,
        params.teams ? params.teams : [],
        activeTeams
      )
    );
  }
  if (client.meta_hierarchy_dependencies) {
    Object.keys(client.meta_hierarchy_dependencies).forEach((k) => {
      if (client.meta_hierarchy_dependencies[k]) {
        const labs = client.hierarchy_dependencies
          .filter(
            (h) =>
              h.level_type_number ===
              client.meta_hierarchy_dependencies[k].level_type_number
          )
          .map((h) => h.level_name);
        if (labs.length > 0) {
          filters.push(
            buildMultipleChoiceFilter(
              labs.map((l: any) => ({ key: l, label: l })),
              client.meta_hierarchy_dependencies[k].level_type_name,
              k
            )
          );
        } else {
          const metaHierarchyDependency = client.meta_hierarchy_dependencies[k];
          const options =
            metaHierarchyDependency.label_type_type === "SINGLE_CHOICE"
              ? (metaHierarchyDependency.label_type_options ?? []).map(
                  (o: any) => ({
                    key: o.key,
                    label: o.value,
                  })
                )
              : _.uniq(teams.map((t) => t[k])).map((o: string) => ({
                  key: o,
                  label: o,
                }));
          filters.push(
            buildMultipleChoiceFilter(
              options,
              metaHierarchyDependency.level_type_name,
              k
            )
          );
        }
      }
    });
  }
};

interface IIsDefaultListAttribute {
  listType?: LIST_TYPE;
  tag?: string;
}
const isDefaultListAttribute = ({ listType, tag }: IIsDefaultListAttribute) => {
  const CUSTOMER_DEFAULT_ATTRIBUTES = ["_name", "_type"];
  if (listType === LIST_TYPE.CUSTOMER) {
    return _.includes(CUSTOMER_DEFAULT_ATTRIBUTES, tag);
  }

  const SKU_DEFAULT_ATTRIBUTES = ["_name", "_brand", "_category"];
  if (listType === LIST_TYPE.SKU) {
    return _.includes(SKU_DEFAULT_ATTRIBUTES, tag);
  }
  return false;
};

const buildOwnerFilter = (
  scope: LIST_SCOPE,
  mobileUsers: IMobileUser[],
  teams: ITeam[],
  lang: TLang
) => {
  let ownersOptions: IOption[] = [];
  if ([LIST_SCOPE.MOBILE_USER, LIST_SCOPE.SINGLE_MOBILE_USER].includes(scope)) {
    ownersOptions = mobileUsers.map((u) => ({
      key: u.id,
      label: `${u.first_name} ${u.last_name}`,
    }));
  } else if ([LIST_SCOPE.TEAM, LIST_SCOPE.SINGLE_TEAM].includes(scope)) {
    ownersOptions = teams
      .filter((t) => !t.hasOwnProperty("active") || t.active)
      .map((t) => ({
        key: t.id,
        label: t.name,
      }));
    return buildMultipleChoiceFilter(
      [{ key: "_", label: "NOTHING" }].concat(ownersOptions),
      lang.components.filters.owners,
      FILTER_TAG.OWNERS
    );
  }
  return buildMultipleChoiceFilter(
    [{ key: "_", label: "NOTHING" }].concat(ownersOptions),
    lang.components.filters.owners,
    FILTER_TAG.OWNERS
  );
};
