import { useEffect, useState } from "react";

import { IActivityReport as IBEActivityReport } from "fieldpro-tools";
import { useSelector } from "react-redux";

import { BasicDialog } from "components/Dialog/BasicDialog";
import KeyUpAndDownEvent from "components/KeyUpAndDownEvent/KeyUpAndDownEvent";
import { getSignedInUser } from "containers/authentication/redux/selector";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import {
  computeMetaExpressionsAction,
  resetMetaExpressionsAction,
} from "containers/workflows/redux/meta_expressions/actions";
import useActions, { useActionsLegacy } from "hooks/useActions";
import { TViewMode } from "model/application/modal/CreateEditModal";
import {
  IActivity,
  IActivityReport,
  IActivityReportInTable,
  IWorkflow,
  IWorkflowStep,
} from "model/entities/Workflow";

import { getMetaExpressionsFromActivity } from "../../reports/utils";
import { isReviewActivity } from "../../workflows/utils/reviewActivityUtils";
import prepareReportStateForBackend from "../utils/prepareReportStateForBackend";
import ActivityReportDialogOuterContent from "./ActivityReportDialogOuterContent";

export interface IActivityReportDialog {
  initViewMode?: TViewMode;
  report: IActivityReportInTable;
  open?: boolean;
  workflow: IWorkflow;
  onPrev?: () => void;
  onNext?: () => void;
  onClose: () => void;
  onDelete?: (report: IActivityReport) => void;
  onSave?: (report: IActivityReport) => void;
  stepId?: string;
  stepReviewed?: IWorkflowStep;
  activity: IActivity;
  disableEdition?: boolean;
}

const ActivityReportDialog = ({
  initViewMode = "VIEW",
  report,
  open,
  onPrev,
  onNext,
  onClose,
  onDelete,
  onSave,
  activity,
  workflow,
  stepId,
  stepReviewed,
  disableEdition,
}: IActivityReportDialog) => {
  const selectedClient = useSelector(getSelectedClient);
  const signedInUser = useSelector(getSignedInUser);
  const mobileUsers = useSelector(allMobileUsersSelector);
  const computeMetaExpressions = useActions(computeMetaExpressionsAction);
  const metaExpressionsInActivity = getMetaExpressionsFromActivity(activity);
  const [viewMode, setViewMode] = useState<TViewMode>(initViewMode);

  const resetMetaExpressionValues = useActionsLegacy({
    resetMetaExpressionsAction,
  }).resetMetaExpressionsAction;

  const metaExpressionsToCompute = metaExpressionsInActivity.filter(
    (me) =>
      !me.includes("__active_matrix_cell") && !me.includes("__active_item_cell")
  );

  const linkedMobileUser = mobileUsers.find(
    (mu) => mu.email && mu.email === signedInUser.id
  );

  useEffect(() => {
    const activity_report = prepareReportStateForBackend(
      report,
      activity
    ) as IBEActivityReport;

    computeMetaExpressions("", metaExpressionsToCompute, {
      activity_report,
      client_id: selectedClient.id,
      ...(activity_report._workflow_report_id // for resolving user scope
        ? { workflow_report_id: activity_report._workflow_report_id }
        : { user_id: linkedMobileUser?.id ?? "" }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KeyUpAndDownEvent
      onKeyUp={viewMode === "VIEW" ? onPrev : undefined}
      onKeyDown={viewMode === "VIEW" ? onNext : undefined}
    >
      <BasicDialog
        open={Boolean(open)}
        onClose={onClose}
        minWidth={"70vw"}
        maxWidth={"70vw"}
        minHeight={isReviewActivity(activity?.type) ? "50vh" : "80vh"}
        alignTop
        data-testid="activity-report-detail-dialog"
      >
        <ActivityReportDialogOuterContent
          // IMPORTANT: the purpose of this component is to be able to add the key here
          // (and not on the ActivityReportDialog which would cause the dialog component to blink on prev/next arrows)
          key={report._id}
          setViewMode={setViewMode}
          viewMode={viewMode}
          report={report}
          workflow={workflow}
          onClose={() => {
            resetMetaExpressionValues();
            onClose();
          }}
          onDelete={onDelete}
          onPrev={onPrev}
          onNext={onNext}
          onSave={onSave}
          activity={activity}
          stepId={stepId}
          stepReviewed={stepReviewed}
          disableEdition={disableEdition}
        />
      </BasicDialog>
    </KeyUpAndDownEvent>
  );
};

export default ActivityReportDialog;
