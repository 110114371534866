/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IMetaExpressionOptions,
  IMetaExpressionOptionsInBE,
  resolveRawMetaExpressionInString,
  resolveRawMetaExpressionInSum,
} from "fieldpro-tools";

import { computeMetaExpressionApiCall } from "containers/workflows/redux/meta_expressions/api";

export const resolveMetaExpressionInStringInBE = async (
  string: string,
  options: IMetaExpressionOptionsInBE
) => {
  const re = /\${[^${]*}/g;
  const expressions: string[] = [];
  let exp;
  do {
    exp = re.exec(string);
    if (exp) {
      expressions.push(exp.map((e) => e.substr(2, e.length - 3))[0]);
    }
  } while (exp);

  const response = await computeMetaExpressionApiCall("", expressions, options);
  const resolvedMeta = response.data.data.result;

  for (const e of expressions) {
    string = string.replace("${" + e + "}", resolvedMeta[e]);
  }

  return string;
};

export const resolveMetaExpressionInString = (
  string: string,
  options: IMetaExpressionOptions,
  undefinedString?: string
) => {
  const re = /\${[^${]*}/g;
  const expressions: string[] = [];
  let exp;
  do {
    exp = re.exec(string);
    if (exp) {
      expressions.push(exp.map((e) => e.substr(2, e.length - 3))[0]);
    }
  } while (exp);

  for (const e of expressions) {
    string = string.replace(
      "${" + e + "}",
      options.resolve_in_sum
        ? resolveRawMetaExpressionInSum(e, options).toString()
        : resolveRawMetaExpressionInString(e, options, undefinedString) ?? ""
    );
  }
  return string;
};
